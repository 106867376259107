<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
						<span class="tct_tit">{{$t('i18nn_b1d3482f4406f513')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"> -->
					</el-button>
				</el-col>
			</el-row>
		</div>

		<el-card shadow="never" style="margin-bottom: 5px;" v-loading="loading">

			<el-descriptions title="" :column="4">

				<el-descriptions-item :label="$t('i18nn_b1131a0cc3945b5f')">{{baseData.outWhCode}}</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_fd6620afef3fab05')">{{baseData.whNo}}</el-descriptions-item>

				<el-descriptions-item :label="$t('i18nn_e1d76a34da86da92')+'FBA'">{{baseData.isFbaName}}</el-descriptions-item>

				<el-descriptions-item :label="'FBA'+$t('5acbec83efb27445')">{{baseData.fbaAddrCode}}</el-descriptions-item>
				<el-descriptions-item :label="'FBA'+$t('i18nn_1272ee58ec6ee1ad')+'/'+$t('i18nn_7110aa855f2767a1')+'/'+$t('i18nn_559fed47b0e95407')">{{baseData.relationNo}}</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_e98a6f2582037336')">{{baseData.trTypeName}}</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_0ae081c3b8c4d4a1')">{{baseData.commitDate}}</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_c301b10948889cb5')">{{baseData.planOutDate}}</el-descriptions-item>
				<el-descriptions-item :label="$t('15b3627faddccb1d')">
					<div class="pre-text">
						{{baseData.remark}}
					</div>
				</el-descriptions-item>

			</el-descriptions>

			<el-descriptions title="" :column="4">
				<el-descriptions-item :label="$t('i18nn_8758fd50c87d6c9c')">
					{{ baseData.addr }},{{ baseData.city }},{{ baseData.state }},{{ baseData.country }}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_5a9fb0915ecea987')">{{baseData.phone}}</el-descriptions-item>
				<el-descriptions-item :label="$t('c4913ab43009b365')">{{baseData.postalCode}}</el-descriptions-item>
			</el-descriptions>

			<el-descriptions title="">
				<el-descriptions-item :label="$t('f5d43732e3f6cf4d')">
					<ul>
						<li v-for="(item,index) in baseData.fileList" :key="index">
							<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
								:fit="'contain'" :src="item.url" :preview-src-list="baseData.fileList.map(itemPre=> { return itemPre.url})">
								<div slot="error" class="image-slot">
								  <i class="el-icon-document"></i>
								</div>
							</el-image>
							<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
						</li>
					</ul>
				</el-descriptions-item>
			</el-descriptions>

		</el-card>

		<el-card shadow="never" v-loading="loading" style="margin-bottom: 5px;">
			<div slot="header" class="">
				<div style="display: flex;justify-content: space-between;">
					<div>
						<span>{{$t('i18nn_71a647a484c3f5c2')}}</span>
					</div>
				</div>
			</div>

			<el-table id="out-table2" ref="multipleTable" :data="baseData.recordList" stripe :border="true"
				:max-height="$store.state.tableMaxHeight5" style="width: 100%" size="small">

				<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')">
				</el-table-column>

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'60' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'70' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'80' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else-if="'99' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<el-table-column prop="inWhCode" :label="$t('i18nn_559fed47b0e95407')" width="">
				</el-table-column>

				<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
				</el-table-column>



				<!-- <el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
				</el-table-column> -->

				<el-table-column prop="trCtnCount" :label="$t('i18nn_bdc361ba04506136')" width="">
				</el-table-column>

				<el-table-column prop="trLabel" :label="$t('i18nn_08e5062e3affb676')" width="">
				</el-table-column>
				<el-table-column prop="trPallte" :label="$t('i18nn_18c1c1a3ea76e9d3')" width="">
				</el-table-column>
				<el-table-column prop="trPallteLabel" :label="$t('i18nn_f88b93f4fd02974c')" width="">
				</el-table-column>

				<!-- trLabel: "3"
				trPallte: "23.0"
				trPallteLabel: "92" -->

				<!-- <el-table-column prop="ctnCount" :label="$t('i18nn_bdc361ba04506136')" width="">
				</el-table-column>
				
				<el-table-column prop="inWhPallte" :label="$t('i18nn_c8773d8d74202801')" width="">
				</el-table-column>
		
				<el-table-column prop="ctnUnitName" :label="$t('b6bf0a07fe26f74f')" width="">
				</el-table-column>
		
				<el-table-column prop="ctnL" label="单箱尺寸(L*W*H)" min-width="180">
					<template slot-scope="scope">
						<div >
							{{scope.row.ctnL}}*{{scope.row.ctnW}}*{{scope.row.ctnH}}
						</div>
					</template>
				</el-table-column>
		
				<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="">
				</el-table-column>
		
				<el-table-column prop="goodsSku" label="箱内SKU" width="" v-if="'1'==baseData.isShowSku">
				</el-table-column>
		
				<el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width="" v-if="'1'==baseData.isShowSku">
				</el-table-column>
		
				<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width="" v-if="'1'==baseData.isShowSku">
				</el-table-column> -->
				<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top"
					    trigger="hover"
					    ><div class="pre-text">{{ scope.row.remark }}</div>
					    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
					  </el-popover>
					</template>
				</el-table-column> -->
				
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 10px;" v-if="80!=scope.row.status">
								<el-button @click="commitCancelAction($event, scope.row)" type="danger" size="small"
									icon="el-icon-delete">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
							</div>
							
						</div>
				
					</template>
				</el-table-column>
				
			</el-table>
		</el-card>

	</div>
</template>
<script>
	// import TransshipmentImport from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentImport.vue';


	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		// 	// mobile:"",
		// 	editId: {
		// 		// default: function() {
		// 		//   return false;
		// 		// },
		// 		// type: Boolean
		// 	},
		// 	openTime: {
		// 		// default: function() {
		// 		//   return '';
		// 		// },
		// 		// type: String
		// 	}
		// },
		components: {
			// WSkuInfo,
		},
		data() {
			return {

				id: '',

				baseData: {},

				loading: false,

				loading_det: false,

				loading_load: false,


			};
		},
		// watch: {
		// 	'$route.query': function(newVal, oldVal) {
		// 		console.log('$route.query', newVal);
		// 		// if(newVal){
		// 		// this.dialogFormVisible = true;
		// 		this.initData();
		// 		// }
		// 	}
		// },
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				this.baseData = {};
				if (this.$route.query.id) {
					this.id = this.$route.query.id;
					this.getPageBaseData(this.id);
				}

			},
			goBack() {
				this.$router.push({
					name: 'ClientTransportOutWh'
				});
			},

			//请求分页数据
			getPageBaseData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferOutEditQuery + '/' + id, {})
					.then(({
						data
					}) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						this.baseData = data.data;
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			
			//撤销
			commitCancelAction(event, row) {
				event.stopPropagation();
				// let parm = [];
			
			// 	if (row) {
			// 		//     //单条
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 	 if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			// 			return;
			// 		}
			// 		// if (dataList.findIndex(item => '10' != item.status) > -1) {
			// 		// 	this.$message.warning('只有全部为未入库状态数据才可操作');
			// 		// 	return;
			// 		// }
			
			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}
			
				this.$confirm(this.$t('i18nn_0174ec83eeb25914')+'?', this.$t('daaaeb1b7b22b126'), {
					type: 'warning'
				}).then(() => {
					// let parm = [];
					this.postData(this.$urlConfig.WhTransferOutWhDelRemove+'/'+row.userId, {
						"id": row.id
					}, ()=>{
						this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
						this.getPageBaseData(this.id);
					});
				}).catch(() => {
			
				});
			},
			

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_transfer_Inwh_type', 'wh_transfer_arrive_way',
			// 			'wh_size_unit'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_transfer_Inwh_type = data.data['wh_transfer_Inwh_type'];
			// 				// this.selectOption.wh_transfer_arrive_way = data.data['wh_transfer_arrive_way'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
